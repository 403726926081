import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { formatters } from 'utils';
import AnimateHeight from 'react-animate-height';
import Icon from 'components/common/Icon';
import { FormGroup, renderBlockErrors } from 'components/views/StructuredContentBuilder';

const Item = ({
  uid,
  label,
  slug,
  url,
  external,
  target,
  errors = {},
  hasChildren,
  isEditing,
  isExpanded,
  connectDragSource,
  onChange,
  onDisclosureClick,
  onEditClick,
  onDeleteClick,
}) => {
  let dragHandle = (
    <span className="btn-move ml-1" title="Drag to sort">
      <Icon name="menu" />
    </span>
  );
  if (connectDragSource) {
    dragHandle = connectDragSource(dragHandle);
  }

  const handleSlugBlur = () => {
    if (!slug) onChange({ slug: formatters.slug(label) });
  };

  const renderedErrors = renderBlockErrors(errors);

  let iconName;
  if (external) {
    iconName = 'open_in_new';
  } else if (target) {
    iconName = target.objectIconName;
  } else {
    iconName = 'question_mark';
  }

  return (
    <div style={{ position: 'relative' }}>
      <div className={classNames({
        'sc-builder-item': true,
        error: Object.keys(errors).length > 0,
        expanded: isExpanded,
        editing: isEditing,
      })}
      >
        <header>
          {hasChildren && <div className="disclosure" onClick={onDisclosureClick} />}
          <div style={{ display: 'flex', alignItems: 'center', flex: '1 0 0%', overflow: 'hidden' }}>
            <Icon name={iconName} size={20} className="text-meta mr-1" />
            <h5
              className="mb-0"
              style={{ textTransform: 'capitalize', cursor: 'pointer' }}
              onClick={onEditClick}
            >
              {label}
            </h5>
          </div>
          <div className="d-flex align-items-center">
            <span
              className="btn-delete tooltip tooltip-left mx-1"
              onClick={onDeleteClick}
              data-tooltip="Remove Item"
            >
              <Icon name="delete" fill />
            </span>

            <span
              className={classNames('btn-edit tooltip tooltip-left mx-1', isEditing && 'active')}
              onClick={onEditClick}
              data-tooltip="Edit Block"
            >
              <Icon name="edit" />
            </span>

            {dragHandle}
          </div>
        </header>
        <AnimateHeight duration={500} height={isEditing ? 'auto' : 0}>
          <div className="content">
            {errors.nonFieldErrors && errors.nonFieldErrors.length > 0 && (
              <div className="error-list" style={{ margin: '-10px -10px .6rem', borderWidth: '0 0 1px', padding: 10 }}>
                {errors.nonFieldErrors.map((msg, i) => <p key={i}>{msg}</p>)}
              </div>
            )}

            <FormGroup className="mb-3" label="Label:">
              <input
                type="text"
                className="form-input"
                onChange={e => onChange({ label: e.target.value })}
                value={label}
              />
              {renderedErrors.label}
            </FormGroup>

            <FormGroup label="URL Slug:">
              <input
                type="text"
                className="form-input"
                onChange={e => onChange({ slug: formatters.slug(e.target.value) })}
                onBlur={handleSlugBlur}
                value={slug}
              />
              {renderedErrors.slug}
            </FormGroup>

            <FormGroup className="mb-3">
              <div className="form-input-hint">This text will appear in the URL and must contain only lowercase letters, numbers, and hyphens.</div>
            </FormGroup>

            {external ? (
              <>
                <FormGroup label="URL:">
                  <input
                    type="text"
                    className="form-input"
                    onChange={e => onChange({ url: e.target.value })}
                    value={url}
                  />
                  {renderedErrors.url}
                </FormGroup>
                <FormGroup className="mb-3">
                  <div className="form-input-hint">Enter a target URL for this custom link.</div>
                </FormGroup>
              </>
            ) : (
              <FormGroup label="Target:">
                {target ? (
                  <div className="d-flex align-items-center">
                    <Icon name={target.objectIconName} size={20} className="text-meta mr-1" />
                    <div style={{ flex: 1 }}>{target.objectName} ({target.objectType})</div>
                  </div>
                ) : (
                  renderedErrors.target
                )}
              </FormGroup>
            )}
          </div>
        </AnimateHeight>
      </div>
    </div>
  );
};

Item.propTypes = {
  uid: PropTypes.string,
  label: PropTypes.string,
  slug: PropTypes.string,
  url: PropTypes.string,
  external: PropTypes.bool,
  target: PropTypes.shape({
    id: PropTypes.string,
    uid: PropTypes.string,
    objectId: PropTypes.string,
    objectUid: PropTypes.string,
    objectType: PropTypes.string,
    objectName: PropTypes.string,
    objectIconName: PropTypes.string,
  }),
  errors: PropTypes.object,
  hasChildren: PropTypes.bool,
  isEditing: PropTypes.bool,
  isExpanded: PropTypes.bool,
  connectDragSource: PropTypes.func,
  onChange: PropTypes.func,
  onDisclosureClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

export default Item;
