import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';


class DragDropFileInput extends Component {
  render () {
    const {
      allowedTypes = ['image', 'audio'],
      multiple = false,
      helpText,
      onFileDrop,
      ...rest
    } = this.props;

    const { disabled = false } = rest;

    const imageTypes = [
      'image/gif',
      'image/jpeg',
      'image/png',
      '.jpg',
      '.jpeg',
      '.gif',
      '.png',
    ];

    const audioTypes = [
      'audio/*',
      '.mp3',
      '.wav',
    ];

    const documentTypes = [
      'application/pdf',
      '.pdf',
    ];

    const accept = [
      ...(allowedTypes.includes('image') ? imageTypes : []),
      ...(allowedTypes.includes('audio') ? audioTypes : []),
      ...(allowedTypes.includes('document') ? documentTypes : []),
    ].join(',');

    return (
      <Dropzone
        accept={accept}
        className="drag-drop-file-input"
        activeClassName="active"
        rejectClassName="reject"
        ref={node => this.dropzoneRef = node}
        onDrop={onFileDrop}
        multiple={multiple}
        disableClick
        {...rest}
      >
        {({ isDragActive, isDragReject }) => (
          <div style={{ opacity: disabled ? 0.5 : 1 }}>
            {isDragReject ? (
              <h5 className="text-error">Unsupported file type</h5>
            ) : (
              <h5>{helpText || (multiple ? 'Drag files here' : 'Drag a file here')}</h5>
            )}
            <div className="separator">– or –</div>
            <div>
              <button
                className="btn"
                disabled={disabled}
                onClick={() => this.dropzoneRef.open()}
              >
                Choose File{multiple ? 's' : ''}
              </button>
            </div>
          </div>
        )}
      </Dropzone>
    );
  }
}

DragDropFileInput.propTypes = {
  allowedTypes: PropTypes.arrayOf(
    PropTypes.oneOf(['image', 'audio', 'document']),
  ),
  multiple: PropTypes.bool,
  helpText: PropTypes.string,
  onFileDrop: PropTypes.func,
};

DragDropFileInput.defaultProps = {
  multiple: true,
};

export default DragDropFileInput;
