import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useClickOutside } from 'hooks';
import { connect } from 'react-redux';
import ObjectDelete from 'components/common/ObjectDelete';
import ObjectDuplicate from 'components/common/ObjectDuplicate';
import MetadataImport from 'components/common/MetadataImport';
import ReleaseBulkPublish from 'components/common/ReleaseBulkPublish';
import ReleaseConfigureAnalytics from 'components/common/ReleaseConfigureAnalytics';
import UpdateMediaSource from 'components/common/UpdateMediaSource';
import Icon from 'components/common/Icon';

const ActionMenu = ({
  workspaceAdmin = false,
  linkActions = [],
  deleteFormUrl,
  duplicateFormUrl,
  model,
  mediaType,
  batchMode,
  selectedIds = [],
}) => {
  const menuRef = useRef();
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleButtonClick = () => setMenuIsOpen(!menuIsOpen);
  const handleClickOutside = () => setMenuIsOpen(false);
  useClickOutside(menuRef, handleClickOutside);

  // type-specific actions
  const extraActions = [];
  if (['media', 'title', 'release'].includes(model)) {
    extraActions.push(<MetadataImport key="MetadataImport" model={model} ids={selectedIds} />);
  }
  if (model === 'media' && ['audio', 'video'].includes(mediaType) && !batchMode) {
    extraActions.push(<UpdateMediaSource key="UpdateMediaSource" id={selectedIds[0]} />);
  }
  if (model === 'release' && batchMode) {
    extraActions.push(<ReleaseConfigureAnalytics key="ReleaseConfigureAnalytics" ids={selectedIds} />);
    extraActions.push(<ReleaseBulkPublish key="ReleaseBulkPublish" ids={selectedIds} />);
  }

  return (
    <div className={classNames('action-menu', menuIsOpen && 'active')} ref={menuRef}>
      <button
        className="btn btn-primary action-menu-toggle"
        onClick={handleButtonClick}
        disabled={batchMode && !selectedIds.length}
        type="button"
      >
        Actions <i className="icon icon-caret" />
      </button>
      <ul className="action-menu-list" onClick={() => setMenuIsOpen(false)}>
        {linkActions.map(({ url, linkText, iconName, targetBlank }, i) => (
          <li key={i} className="action-menu-item">
            <a
              href={url}
              target={targetBlank ? '_blank' : null}
              rel={targetBlank ? 'noopener noreferrer' : null}
            >
              {iconName ? <Icon name={iconName} size={20} className="mr-3" /> : null}
              {linkText}
            </a>
          </li>
        ))}
        <li className="action-menu-item">
          {extraActions}
          {!!duplicateFormUrl && <ObjectDuplicate multiple={batchMode} ids={selectedIds} formUrl={duplicateFormUrl} />}
          <ObjectDelete multiple={batchMode} ids={selectedIds} formUrl={deleteFormUrl} />
        </li>
      </ul>
    </div>
  );
};

ActionMenu.propTypes = {
  workspaceAdmin: PropTypes.bool,
  linkActions: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string,
    linkText: PropTypes.string,
    iconName: PropTypes.string,
    targetBlank: PropTypes.bool,
  })),
  deleteFormUrl: PropTypes.string,
  duplicateFormUrl: PropTypes.string,
  model: PropTypes.string,
  mediaType: PropTypes.oneOf(['audio', 'video', 'image', 'pdf']),
  objectId: PropTypes.string,
  objectListKey: PropTypes.string,
  batchMode: PropTypes.bool,
  selectedIds: PropTypes.array,
};

const mapStateToProps = ({ objectList }, ownProps) => {
  const { objectListKey, batchMode, objectId } = ownProps;
  let selectedIds = [objectId];
  if (batchMode) {
    selectedIds = (objectList[objectListKey] && objectList[objectListKey].selectedIds) || [];
  }
  return { selectedIds };
};

export default connect(mapStateToProps)(ActionMenu);
