import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import camelize from 'camelize';
import ErrorBoundary from 'components/common/ErrorBoundary';
import Checkbox from 'components/common/DjangoFormField/Checkbox';
import Text from 'components/common/DjangoFormField/Text';
import ImageVersionSelect from 'components/common/ImageVersionSelect';
import LibraryMediaField from 'components/common/LibraryMediaField';
import FieldWrapper from 'components/views/CustomTitleBuilder/CustomTitleField/FieldWrapper';

const DISPLAY_STYLE_FILL = 'fill';
const DISPLAY_STYLE_FIT = 'fit';

const MENU_POSITION_BOTTOM = 'bottom';
const MENU_POSITION_RIGHT = 'right';

const ROWS_AUTO = 'auto';
const ROWS_FIXED = 'fixed';


const CoverLayout = ({
  fieldNames,
  formData,
  layouts,
}) => {
  const [fields, setFields] = useState({});
  const [rowsStyle, setRowsStyle] = useState(ROWS_AUTO);
  const [rowsPerPageInitialVal, setRowsPerPageInitialVal] = useState(1);

  const updateField = (fieldName, value) => setFields(oldState => ({ ...oldState, [fieldName]: value }));

  const handleInputChange = evt => {
    let { name, value, type, checked } = evt.target;
    if (type === 'checkbox') {
      value = !!checked;
    }
    updateField(name, value);
  };

  const handleRowsStyleChange = evt => setRowsStyle(evt.target.value);

  const initFormData = () => {
    const fields = fieldNames.reduce((result, name) => {
      const val = formData.is_bound ? formData.fields[name].value : formData.fields[name].initial;
      result[name] = val;
      return result;
    }, {});

    setFields(fields);
    setRowsStyle(fields.rows_per_page ? ROWS_FIXED : ROWS_AUTO);
    setRowsPerPageInitialVal(fields.rows_per_page || 1);
  };

  useEffect(() => {
    initFormData();
  }, []);

  useEffect(() => {
    updateField('rows_per_page', rowsStyle === ROWS_FIXED ? rowsPerPageInitialVal : '');
  }, [rowsStyle]);

  const layoutChoices = layouts.length > 0 && camelize(layouts).map(({ slug, label, description, thumbnailUrl }) => {
    const handleSelect = () => updateField('layout', slug);
    const isSelected = fields.layout === slug;

    return (
      <div key={slug} className={classNames('cover-layout-item', 'card-item', isSelected && 'selected')} onClick={handleSelect}>
        <div className="cover-layout-thumbnail">
          <img src={thumbnailUrl} />
        </div>
        <footer>
          <div className="cover-layout-radio">
            <label className="form-radio">
              <input
                type="radio"
                name="layout"
                value={slug}
                checked={fields.layout === slug}
                onChange={handleSelect}
              />
              <i className="form-icon" />
            </label>
          </div>
          <div className="cover-layout-label">
            <h5 className="m-0">{label}</h5>
            {!!description && <div className="text-meta mt-1">{description}</div>}
          </div>
        </footer>
      </div>
    );
  });

  const activeLayout = layouts.find(l => l.slug === fields.layout);
  let optionsForm = null;
  if (activeLayout) {
    const validFieldNames = fieldNames.filter(name => (
      name !== 'layout' && !(activeLayout.exclude_fields || []).includes(name)
    ));

    const renderField = (name, children) => validFieldNames.includes(name) ? children : (
      <input key={name} type="hidden" name={name} value={typeof fields[name] !== 'undefined' ? fields[name] : ''} />
    );

    const fieldItems = [
      ['show_name', (
        <Checkbox
          key="show_name"
          name="show_name"
          label="Show name"
          helpText="Display this Cover’s name heading when no individual item is selected."
          value={fields.show_name}
          errors={formData.errors.show_name || []}
          className="mb-3"
          onChange={handleInputChange}
        />
      )],
      ['show_description', (
        <Checkbox
          key="show_description"
          name="show_description"
          label="Show description"
          helpText="Display this Cover’s short description text when no individual item is selected."
          value={fields.show_description}
          errors={formData.errors.show_description || []}
          className="mb-3"
          onChange={handleInputChange}
        />
      )],
      ['dim_inactive', (
        <Checkbox
          key="dim_inactive"
          name="dim_inactive"
          label="Dim inactive items"
          helpText="Slightly dim all items except the one currently under the mouse pointer."
          value={fields.dim_inactive}
          errors={formData.errors.dim_inactive || []}
          className="mb-3"
          onChange={handleInputChange}
        />
      )],
      ['button_text', (
        <Text
          key="button_text"
          name="button_text"
          label="Button text"
          helpText="Text for call-to-action button displayed when the Cover is first loaded."
          value={fields.button_text || ''}
          errors={formData.errors.button_text || []}
          className="mb-3"
          onChange={handleInputChange}
        />
      )],
      ['background_image', (
        <FieldWrapper
          key="background_image"
          name="background_image"
          label="Background image"
          helpText="Select a default background image for this Cover. This image will be displayed when no individual item is selected, on platforms that do not support background video."
          errors={formData.errors.background_image || []}
        >
          <LibraryMediaField
            inputName="background_image"
            buttonText="Choose Image"
            limitTypes={['image']}
            value={fields.background_image || ''}
            onChange={value => updateField('background_image', value)}
          />
        </FieldWrapper>
      )],
      ['background_video', (
        <FieldWrapper
          key="background_video"
          name="background_video"
          label="Background video"
          helpText="Select a default background video for this Cover. This video will be displayed when no individual item is selected."
          errors={formData.errors.background_video || []}
        >
          <LibraryMediaField
            inputName="background_video"
            buttonText="Choose Video"
            limitTypes={['video']}
            value={fields.background_video || ''}
            onChange={value => updateField('background_video', value)}
          />
        </FieldWrapper>
      )],
      ['loop_video', (
        <Checkbox
          key="loop_video"
          name="loop_video"
          label="Loop video playback"
          value={fields.loop_video}
          errors={formData.errors.loop_video || []}
          className="mb-3"
          onChange={handleInputChange}
        />
      )],
      ['advance_on_video_end', (
        <Checkbox
          key="advance_on_video_end"
          name="advance_on_video_end"
          label="Navigate to first cover item when playback completes"
          value={fields.advance_on_video_end}
          errors={formData.errors.advance_on_video_end || []}
          className="mb-3"
          onChange={handleInputChange}
        />
      )],
      ['vertical_buttons', (
        <Checkbox
          key="vertical_buttons"
          name="vertical_buttons"
          label="Stack buttons vertically"
          value={fields.vertical_buttons}
          errors={formData.errors.vertical_buttons || []}
          className="mb-3"
          onChange={handleInputChange}
        />
      )],
      ['text_placement', (
        <FieldWrapper
          key="text_placement"
          label="Text/button position"
          errors={formData.errors.text_placement || []}
        >
          {[
            ['topLeft', 'Top Left'],
            ['topCenter', 'Top Center'],
            ['topRight', 'Top Right'],
            ['centerLeft', 'Center Left'],
            ['center', 'Center'],
            ['centerRight', 'Center Right'],
            ['bottomLeft', 'Bottom Left'],
            ['bottomCenter', 'Bottom Center'],
            ['bottomRight', 'Bottom Right'],
          ].map(([value, label]) => (
            <label className="form-radio" key={value}>
              <input
                type="radio"
                name="text_placement"
                value={value}
                checked={fields.text_placement === value}
                onChange={handleInputChange}
              />
              <i className="form-icon" />
              <span>{label}</span>
            </label>
          ))}
        </FieldWrapper>
      )],
      ['animation_delay', (
        <FieldWrapper
          key="animation_delay"
          name="animation_delay"
          label="Animation delay"
          helpText="Specify the delay, in seconds, before text and overlay elements animate in. Set a value of 0 to disable this animation."
          errors={formData.errors.animation_delay || []}
        >
          <input
            type="number"
            step="0.1"
            className="form-input"
            name="animation_delay"
            value={fields.animation_delay || 0}
            onChange={handleInputChange}
            style={{ width: 'unset' }}
          />
        </FieldWrapper>
      )],
      ['items_per_page', (
        <FieldWrapper
          key="items_per_page"
          name="items_per_page"
          label="Max items per page"
          helpText="Specify the maximum number of cover items displayed per page."
          errors={formData.errors.items_per_page || []}
        >
          <input
            type="number"
            step="1"
            className="form-input"
            name="items_per_page"
            value={fields.items_per_page || ''}
            onChange={handleInputChange}
            style={{ width: 'unset' }}
          />
        </FieldWrapper>
      )],
      ['rows_per_page', (
        <FieldWrapper
          key="rows_per_page"
          name="rows_per_page"
          label="Rows per page"
          errors={formData.errors.rows_per_page || []}
        >
          <label className="form-radio">
            <input
              type="radio"
              value={ROWS_AUTO}
              checked={rowsStyle === ROWS_AUTO}
              onChange={handleRowsStyleChange}
            />
            <i className="form-icon" />
            <span>Automatic</span>
            <div className="text-hint">Calculate the number of grid rows automatically.</div>
          </label>

          <label className="form-radio">
            <input
              type="radio"
              value={ROWS_FIXED}
              checked={rowsStyle === ROWS_FIXED}
              onChange={handleRowsStyleChange}
            />
            <i className="form-icon" />
            <span>Fixed</span>
            <div className="text-hint">Display a fixed number of grid rows.</div>
            <input
              type="number"
              step="1"
              min={1}
              max={6}
              className="form-input"
              name="rows_per_page"
              disabled={rowsStyle !== ROWS_FIXED}
              value={fields.rows_per_page || ''}
              onChange={handleInputChange}
              style={{ width: 80, marginTop: 5 }}
            />
          </label>
        </FieldWrapper>
      )],
      ['menu_position', (
        <FieldWrapper
          key="menu_position"
          label="Menu position"
          errors={formData.errors.menu_position || []}
        >
          <label className="form-radio">
            <input
              type="radio"
              name="menu_position"
              value={MENU_POSITION_BOTTOM}
              checked={fields.menu_position === MENU_POSITION_BOTTOM}
              onChange={handleInputChange}
            />
            <i className="form-icon" />
            <span>Bottom</span>
          </label>

          <label className="form-radio">
            <input
              type="radio"
              name="menu_position"
              value={MENU_POSITION_RIGHT}
              checked={fields.menu_position === MENU_POSITION_RIGHT}
              onChange={handleInputChange}
            />
            <i className="form-icon" />
            <span>Right</span>
          </label>
        </FieldWrapper>
      )],
      ['image_version', (
        <FieldWrapper
          key="image_version"
          name="image_version"
          label="Image version"
          helpText="Specify the preview image version to use for cover items."
          errors={formData.errors.image_version || []}
        >
          <ImageVersionSelect
            includeOriginal={false}
            value={fields.image_version}
            onChange={val => updateField('image_version', val)}
          />
          <input type="hidden" name="image_version" value={fields.image_version || ''} />
        </FieldWrapper>
      )],
      ['display_style', (
        <FieldWrapper
          key="display_style"
          label="Item display style"
          errors={formData.errors.display_style || []}
        >
          <label className="form-radio">
            <input
              type="radio"
              name="display_style"
              value={DISPLAY_STYLE_FILL}
              checked={fields.display_style === DISPLAY_STYLE_FILL}
              onChange={handleInputChange}
            />
            <i className="form-icon" />
            <span>Fill display area</span>
            <div className="text-hint">Image/video content fills available space. Cropping may occur.</div>
          </label>

          <label className="form-radio">
            <input
              type="radio"
              name="display_style"
              value={DISPLAY_STYLE_FIT}
              checked={fields.display_style === DISPLAY_STYLE_FIT}
              onChange={handleInputChange}
            />
            <i className="form-icon" />
            <span>Fit to display area</span>
            <div className="text-hint">Image/video content fits within available space. Letterboxing may occur.</div>
          </label>
        </FieldWrapper>
      )],
    ];

    optionsForm = fieldItems.map(item => renderField(...item));
  }

  const nonFieldErrors = formData.non_field_errors.length > 0 && formData.non_field_errors;
  const layoutErrors = formData.errors.layout;

  return (
    <div className="object-detail-content">
      <div className="object-detail-main">
        {nonFieldErrors && (
          <div className="error-list mb-4">
            {nonFieldErrors.map((msg, idx) => <p key={idx}>{msg}</p>)}
          </div>
        )}
        <h4 className="alt-head mb-1">Layout</h4>
        <hr className="mt-0 mb-4" />
        {layoutErrors && (
          <div className="error-list mb-4">
            {layoutErrors.map((msg, idx) => <p key={idx}>{msg}</p>)}
          </div>
        )}
        <div className="cover-layout-item-container">
          <ErrorBoundary>
            {layoutChoices || null}
          </ErrorBoundary>
        </div>
      </div>
      <div className="object-detail-sidebar">
        <h4 className="alt-head mb-1">Options</h4>
        <hr className="mt-0 mb-4" />
        <ErrorBoundary>
          {optionsForm}
        </ErrorBoundary>
      </div>
    </div>
  );
};

CoverLayout.propTypes = {
  formData: PropTypes.object.isRequired,
  layouts: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
    exclude_fields: PropTypes.arrayOf(PropTypes.string),
  })).isRequired,
  fieldNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CoverLayout;
