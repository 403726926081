import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import striptags from 'striptags';
import urlJoin from 'url-join';
import { urls } from 'app-constants';
import renderBlockErrors from './renderBlockErrors';
import Select from 'components/common/Select';
import QuillEditor from 'components/common/QuillEditor';
import MediaRelationField from 'components/views/CustomTitleBuilder/CustomTitleField/MediaRelationField';


const Media = ({
  uid,
  errors,
  media,
  description,
  descriptionSource,
  credit,
  creditSource,
  linkUrl,
  size,
  backgroundVideo,
  loop,
  hidePoster,
  onPreviewTextUpdate,
  onChange,
}) => {
  const [attachedMedia, setAttachedMedia] = useState(null);

  useEffect(() => {
    onPreviewTextUpdate(attachedMedia ? attachedMedia.name : '');
  }, [attachedMedia]);

  const handleFieldChange = (name, val) => onChange({ [name]: val });

  const sizeOptions = [
    { value: 'small', label: 'Small' },
    { value: 'medium', label: 'Medium' },
    { value: 'large', label: 'Large' },
    { value: 'full', label: 'Full width' },
  ];
  const sizeSelectVal = sizeOptions.find(opt => opt.value === size);

  const fetchData = mediaId => {
    if (!mediaId) return;
    const url = urlJoin(urls.mediaDataBase, mediaId, '?format=json');
    fetch(url, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          console.error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => setAttachedMedia(data))
      .catch(err => console.error(err));
  };

  useEffect(() => {
    if (media) {
      fetchData(media);
    } else {
      setAttachedMedia(null);
    }
  }, [media]);

  let linkUrlFieldDisabled = attachedMedia && attachedMedia.type !== 'Image';
  if (attachedMedia && attachedMedia.type === 'Video' && backgroundVideo) {
    linkUrlFieldDisabled = false;
  }

  const formatDesc = val => striptags(val.replace(/<\/p>\s*<p>/gi, '</p>\n\n<p>'));

  const descriptionSourceOptions = [
    { value: 'media_long_desc', label: 'Use media long description' },
    { value: 'media_short_desc', label: 'Use media short description' },
    { value: 'custom', label: 'Use custom description' },
    { value: 'none', label: 'None' },
  ];
  const descriptionSourceValue = descriptionSourceOptions.find(opt => opt.value === descriptionSource);

  const creditSourceOptions = [
    { value: 'media_credit', label: 'Use media credit' },
    { value: 'custom', label: 'Use custom credit' },
    { value: 'none', label: 'None' },
  ];
  const creditSourceValue = creditSourceOptions.find(opt => opt.value === creditSource);

  let derivedDescriptionValue = '';
  if (descriptionSource === 'media_long_desc') {
    derivedDescriptionValue = attachedMedia ? formatDesc(attachedMedia.longDescription) : '';
  }
  if (descriptionSource === 'media_short_desc') {
    derivedDescriptionValue = attachedMedia ? formatDesc(attachedMedia.shortDescription) : '';
  }
  const descriptionInput = descriptionSource === 'custom' ? (
    <QuillEditor
      value={description}
      onChange={val => handleFieldChange('description', val)}
      size="small"
    />
  ) : (
    <textarea
      key={`${uid}-description-${descriptionSource}`}
      cols="30"
      rows="3"
      className="form-input"
      value={derivedDescriptionValue}
      disabled
    />
  );

  let derivedCreditValue = '';
  if (creditSource === 'media_credit') {
    derivedCreditValue = attachedMedia ? striptags(attachedMedia.credit) : '';
  }
  const creditInput = creditSource === 'custom' ? (
    <input
      key={`${uid}-credit-${creditSource}`}
      type="text"
      className="form-input"
      value={credit}
      onChange={e => handleFieldChange('credit', e.target.value)}
    />
  ) : (
    <input
      key={`${uid}-credit-${creditSource}`}
      type="text"
      className="form-input"
      value={derivedCreditValue}
      disabled
    />
  );

  const renderedErrors = renderBlockErrors(errors);

  return (
    <div className="content">
      <MediaRelationField value={media} onChange={val => handleFieldChange('media', val)} />
      {renderedErrors.media}

      <div className="mb-3">
        <div>Description:</div>
        <div className="mb-2">
          <Select
            value={descriptionSourceValue}
            options={descriptionSourceOptions}
            onChange={val => handleFieldChange('descriptionSource', val)}
          />
          {renderedErrors.descriptionSource}
        </div>
        <div>
          {descriptionInput}
          {renderedErrors.description}
        </div>
      </div>

      <div className="mb-3">
        <div>Credit:</div>
        <div className="mb-1">
          <Select
            value={creditSourceValue}
            options={creditSourceOptions}
            onChange={val => handleFieldChange('creditSource', val)}
          />
          {renderedErrors.creditSource}
        </div>
        <div>
          {creditInput}
          {renderedErrors.credit}
        </div>
      </div>

      <div className="mb-3">
        <div>Link URL:</div>
        <div className="mb-1">
          <input
            type="text"
            className="form-input"
            disabled={linkUrlFieldDisabled}
            value={linkUrl || ''}
            onChange={e => handleFieldChange('linkUrl', e.target.value)}
          />
          {renderedErrors.linkUrl}
          <div className="text-hint mt-1">
            {linkUrlFieldDisabled
              ? 'This field is only available for images and ambient video media.'
              : 'Optionally enter a URL for this item to link to.'}
          </div>
        </div>
      </div>

      <div className="mb-3">
        <div>Size:</div>
        <div className="mb-1">
          <Select
            value={sizeSelectVal}
            options={sizeOptions}
            onChange={val => handleFieldChange('size', val)}
          />
          {renderedErrors.size}
        </div>
      </div>

      {attachedMedia && attachedMedia.type === 'Video' && (
        <div className="mb-3">
          <label className="form-checkbox mb-0">
            <input
              type="checkbox"
              checked={backgroundVideo}
              onChange={e => handleFieldChange('backgroundVideo', e.target.checked)}
            />
            <i className="form-icon" /> Ambient video
            <div className="text-hint">Ambient videos autoplay, optionally in a loop, with no audio and no controls.</div>
          </label>
          {renderedErrors.backgroundVideo}
          {backgroundVideo && (
            <>
              <label className="form-checkbox mt-0">
                <input
                  type="checkbox"
                  checked={loop && backgroundVideo}
                  disabled={!backgroundVideo}
                  onChange={e => handleFieldChange('loop', e.target.checked)}
                />
                <i className="form-icon" /> Loop video playback
              </label>
              {renderedErrors.loop}

              <label className="form-checkbox mt-0">
                <input
                  type="checkbox"
                  checked={hidePoster && backgroundVideo}
                  disabled={!backgroundVideo}
                  onChange={e => handleFieldChange('hidePoster', e.target.checked)}
                />
                <i className="form-icon" /> Disable poster frame overlay
              </label>
              {renderedErrors.hidePoster}
            </>
          )}
        </div>
      )}
    </div>
  );
};

Media.propTypes = {
  uid: PropTypes.string,
  errors: PropTypes.object,
  media: PropTypes.string,
  description: PropTypes.string,
  descriptionSource: PropTypes.oneOf(['media_long_desc', 'media_short_desc', 'custom', 'none']),
  credit: PropTypes.string,
  creditSource: PropTypes.oneOf(['media_credit', 'custom', 'none']),
  linkUrl: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'full']),
  backgroundVideo: PropTypes.bool,
  loop: PropTypes.bool,
  hidePoster: PropTypes.bool,
  onPreviewTextUpdate: PropTypes.func,
  onChange: PropTypes.func,
};

export default Media;
