import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import urlJoin from 'url-join';
import { urls } from 'app-constants';
import { ManagementForm } from 'components/common/inlines';
import Icon from 'components/common/Icon';
import PreviewImage from 'components/common/PreviewImage';
import JobProcessingIndicator from 'components/common/JobProcessingIndicator';
import LibraryMediaField from 'components/common/LibraryMediaField';
import LoadingOverlay from 'components/common/LoadingOverlay';
import FileWidget from './FileWidget';

const FilesPanel = ({
  previewImagesFormsetData,
  hasPreviewSource,
  previewGenerationJobId,
  objectId,
  objectType,
  objectSource,
  masterField,
  masterDetails,
  previewMasterField,
  previewMasterDetails,
  regeneratePreviewsField,
  previewSourceMediaField,
  linkedPreviewItems,
}) => {
  const { forms, prefix, managementForm } = camelize(previewImagesFormsetData);

  const [previewSourceMedia, setPreviewSourceMedia] = useState(null);
  const [useReferenceMedia, setUseReferenceMedia] = useState(false);
  useEffect(() => {
    setUseReferenceMedia(!!previewSourceMediaField.value);
    setPreviewSourceMedia(previewSourceMediaField.value);
  }, [previewSourceMediaField.value]);

  const [isFetching, setIsFetching] = useState(false);
  const [externalPreviewsData, setExternalPreviewsData] = useState([]);
  useEffect(() => {
    if (previewSourceMedia) {
      fetchExternalPreviewsData(previewSourceMedia);
    } else {
      setExternalPreviewsData([]);
    }
  }, [previewSourceMedia]);

  const fetchExternalPreviewsData = uid => {
    const url = urlJoin(urls.mediaPreviewImagesBase, uid, '/');
    setIsFetching(true);
    fetch(url, { credentials: 'include' })
      .then(response => {
        setIsFetching(false);
        if (!response.ok) throw new Error(response.statusText);
        return response;
      })
      .then(response => response.json())
      .then(data => setExternalPreviewsData(camelize(data)))
      .catch(err => {
        console.error(err);
      });
  };

  let pdfUrl;
  if (objectId && objectType === 'pdf') {
    pdfUrl = urlJoin(urls.mediaPdfProxyBase, objectId, '/');
  }

  const hasForms = forms.length > 0;
  let altContent;
  if (!hasForms) {
    if (!hasPreviewSource) {
      altContent = (
        <div className="p-4" style={{ flex: 1 }}>
          <div className="empty">
            <div className="empty-icon">
              <Icon name="imagesmode" size={48} />
            </div>
            <p className="empty-title h3">No Preview Source</p>
            <p className="empty-subtitle">Add a source image to generate default previews.</p>
          </div>
        </div>
      );
    } else if (previewGenerationJobId) {
      altContent = (
        <div className="flex-1">
          <JobProcessingIndicator
            jobID={previewGenerationJobId}
            statusTextActive="Previews are currently processing."
            statusTextComplete="Processing is complete. Save your changes and reload this page to view previews."
            statusTextFailed="Preview processing failed. Toggle the “Regenerate all previews” switch and save to retry."
          />
        </div>
      );
    }
  }

  return (
    <div className="object-detail-content">
      <ManagementForm prefix={prefix} formData={managementForm} totalForms={forms.length} />
      <section className="object-detail-main">
        <div className="flex-spread">
          <h4 className="alt-head mb-1">Image Previews</h4>
          <label className="form-switch" style={{ marginLeft: 30, padding: '.1rem 2rem .1rem 0' }}>
            <input
              type="checkbox"
              checked={useReferenceMedia}
              onChange={evt => setUseReferenceMedia(evt.target.checked)}
            />
            <i className="form-icon" style={{ left: 'unset', right: 0 }} />
            <strong style={{ whiteSpace: 'nowrap' }}>Use Reference Media</strong>
          </label>
        </div>
        <hr className="mt-0 mb-4" />
        {useReferenceMedia && (
          <div className="mb-5">
            {previewSourceMediaField.errors.length === 0 ? (
              <div className="text-hint mb-2">
                Previews will be derived from the selected Media object.
              </div>
            ) : (
              <div className="form-input-hint text-error mb-2">
                {previewSourceMediaField.errors.map((msg, i) => <div key={i}>{msg}</div>)}
              </div>
            )}
            <LibraryMediaField
              inputName={previewSourceMediaField.name}
              buttonText="Choose Media"
              value={previewSourceMedia}
              onChange={setPreviewSourceMedia}
            />
          </div>
        )}

        <div className="media-preview-image-container">
          {useReferenceMedia && externalPreviewsData.map(item => (
            <PreviewImage
              key={item.uid}
              editable={false}
              label={item.label}
              slug={item.slug}
              imgUrl={item.smImg}
              largeImgUrl={item.lgImg}
              isThumbnail={item.isThumbnail}
              aspectRatio={parseFloat(item.aspectRatio)}
            />
          ))}

          {hasForms ? forms.map(({ instance, fields, errors }) => (
            <Fragment key={instance.uid}>
              {!useReferenceMedia && (
                <PreviewImage
                  label={instance.label}
                  slug={instance.slug}
                  imgUrl={instance.smImg}
                  largeImgUrl={instance.lgImg}
                  defaultImgUrl={instance.srcImg}
                  isThumbnail={instance.isThumbnail}
                  aspectRatio={parseFloat(instance.aspectRatio)}
                  hasError={!!Object.keys(errors).length}
                  inputImageName={fields.imageB64.name}
                  inputImageValue={fields.imageB64.value}
                  inputCropName={fields.crop.name}
                  inputCropValue={fields.crop.value}
                  inputUseDefaultName={fields.useDefaultSource.name}
                  inputUseDefaultValue={fields.useDefaultSource.value}
                />
              )}
              <input type="hidden" name={fields.id.name} value={fields.id.value} />
            </Fragment>
          )) : (!useReferenceMedia && altContent)}
          <LoadingOverlay show={isFetching} style={{ backgroundColor: 'rgba(247, 247, 247, .7)' }} />
        </div>
      </section>

      <section className="object-detail-sidebar">
        {objectSource === 'file' && (
          <FileWidget
            labelText="Master File"
            pdfUrl={pdfUrl}
            formField={camelize(masterField)}
            {...camelize(masterDetails || {})}
          />
        )}

        {!useReferenceMedia && (
          <>
            {objectType !== 'image' && (
              <FileWidget
                labelText="Image Preview Source"
                formField={camelize(previewMasterField)}
                {...camelize(previewMasterDetails || {})}
              />
            )}

            {hasPreviewSource && regeneratePreviewsField && (
              <>
                <hr className="mt-3" />
                <div className="form-group mb-3">
                  <label className="form-switch">
                    <input type="checkbox" name={regeneratePreviewsField.name} />
                    <i className="form-icon" /> <strong>Regenerate all previews</strong>
                  </label>
                  <p className="form-input-hint mb-0">
                    If this option is selected, all preview versions will be regenerated from the default source image above, overwriting any custom previews.
                  </p>
                </div>
                <hr />
              </>
            )}
          </>
        )}

        {linkedPreviewItems && (
          <div>
            <h5 className="mb-1">Linked items</h5>
            <div className="text-hint mb-2">
              The following objects reference this Media’s preview images.
            </div>
            {linkedPreviewItems.media.length > 0 && (
              <div className="mb-2">
                <h6 className="mb-1">Media</h6>
                <ul className="mt-0 text-sm" style={{ listStylePosition: 'outside' }}>
                  {linkedPreviewItems.media.map(({ name, url }) => (
                    <li key={url} className="m-0 mb-1">
                      <a href={url} target="_blank" rel="noopener noreferrer">{name}</a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {linkedPreviewItems.titles.length > 0 && (
              <div className="mb-2">
                <h6 className="mb-1">Titles</h6>
                <ul className="mt-0 text-sm" style={{ listStylePosition: 'outside' }}>
                  {linkedPreviewItems.titles.map(({ name, url }) => (
                    <li key={url} className="m-0 mb-1">
                      <a href={url} target="_blank" rel="noopener noreferrer">{name}</a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <hr className="mb-4" />
          </div>
        )}
      </section>
    </div>
  );
};

FilesPanel.propTypes = {
  previewImagesFormsetData: PropTypes.object,
  hasPreviewSource: PropTypes.bool,
  previewGenerationJobId: PropTypes.number,
  objectType: PropTypes.string,
  objectSource: PropTypes.string,
  masterDetails: PropTypes.object,
  masterField: PropTypes.object,
  previewMasterDetails: PropTypes.object,
  previewMasterField: PropTypes.object,
  regeneratePreviewsField: PropTypes.object,
  previewSourceMediaField: PropTypes.object,
  linkedPreviewItems: PropTypes.shape({
    media: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
    })),
    titles: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
    })),
  }),
};

export default FilesPanel;
