import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { formatters } from 'utils';
import MediaPlayer from 'components/common/MediaPlayer';

const FileWidget = ({
  labelText,
  formField,
  type,
  url,
  thumbUrl,
  pdfUrl,
  filename,
  filesize,
  width,
  height,
  duration,
}) => {
  const hasError = !!formField.errors && formField.errors.length > 0;

  const [inputIsVisible, setInputIsVisible] = useState(false);
  const toggleInput = () => setInputIsVisible(oldState => !oldState);
  useEffect(() => {
    if (!url || hasError) setInputIsVisible(true);
  }, []);

  let preview;
  if (thumbUrl) {
    preview = <img src={thumbUrl} alt={filename} />;
  } else if (pdfUrl) {
    preview = (
      <iframe
        src={pdfUrl}
        frameBorder={0}
        style={{
          width: '100%',
          height: 600,
          border: '1px solid #a6a6a6',
        }}
      />
    );
  } else {
    preview = (
      <div className={classNames('player', type)}>
        <MediaPlayer mediaType={type} mediaUrl={url} />
      </div>
    );
  }

  return (
    <div className="master-file mb-6">
      <header className="flex-spread mb-1">
        <h4 className="alt-head m-0">{labelText}</h4>
        {!!url && (
          inputIsVisible
            ? <button type="button" className="replace-cancel btn btn-sm" onClick={toggleInput}>CANCEL</button>
            : <button type="button" className="replace btn btn-primary btn-sm" onClick={toggleInput}>REPLACE</button>
        )}
      </header>

      <hr className="mt-0 mb-4" />

      {inputIsVisible && (
        <div className={classNames('form-group master-file-input', hasError && 'has-error')}>
          <input type="file" className="form-input" name={formField.name} />
          {hasError && (
            <p className="form-input-hint">
              {formField.errors.map(err => <span key={err}>{err}<br /></span>)}
            </p>
          )}
        </div>
      )}

      <div className="master-file-details">
        {!!url && !inputIsVisible && (
          <>
            <div className="media-preview mb-3">
              {preview}
            </div>
            <h5>{filename}</h5>
            {filesize && (
              <div>
                <strong className="mr-1">File Size:</strong> {formatters.bytes(filesize)}
                <span> (<a href={url} target="_blank" rel="noopener noreferrer">download</a>)</span>
              </div>
            )}

            {!!(width && height) && (
              <div><strong className="mr-1">Dimensions:</strong> {width} &times; {height}</div>
            )}

            {!!duration && (
              <div><strong className="mr-1">Duration:</strong> {formatters.seconds(duration)}</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

FileWidget.propTypes = {
  labelText: PropTypes.string,
  formField: PropTypes.object,
  type: PropTypes.string,
  url: PropTypes.string,
  thumbUrl: PropTypes.string,
  pdfUrl: PropTypes.string,
  filename: PropTypes.string,
  filesize: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  duration: PropTypes.number,
};

export default FileWidget;
