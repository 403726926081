import React, { useState, useEffect, useRef, useMemo, Fragment } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import { v4 as uuid4 } from 'uuid';
import ls from 'local-storage';
import { formatters, pd, pluralize } from 'utils';
import withDragDropContext from 'components/common/withDragDropContext';
import ErrorBoundary from 'components/common/ErrorBoundary';
import Icon from 'components/common/Icon';
import NestedSortableItem from 'components/common/NestedSortableItem';
import FieldWrapper from 'components/views/CustomTitleBuilder/CustomTitleField/FieldWrapper';
import CustomLinkForm from './CustomLinkForm';
import Item from './Item';

const MAX_NESTING_LEVEL = 5;

const ADD_POS_BEGINNING = 'beginning';
const ADD_POS_END = 'end';

const FILTER_ALL = 'All';
const FILTER_ADDED = 'Added';
const FILTER_NOT_ADDED = 'Not added';
const FILTER_CHOICES = [FILTER_ALL, FILTER_ADDED, FILTER_NOT_ADDED];

const NAV_STYLE_NESTED = 'nested';
const NAV_STYLE_HYBRID = 'hybrid';

const NavBuilder = ({
  isEnabled = true,
  styleValue,
  initialValue = [],
  errors: errorsFromProps = {},
  maxNestingLevel = MAX_NESTING_LEVEL,
  releaseItems = [],
  onChange = () => {},
  onEnabledChange = () => {},
  onStyleChange = () => {},
}) => {
  const itemListRef = useRef();
  const itemRefs = useRef({});
  const [items, setItems] = useState({ byId: {}, ordered: [] });
  const [itemsStructured, setItemsStructured] = useState([]);
  const includedReleaseItems = Object.values(items.byId).filter(({ target }) => !!target).map(({ target }) => target.uid);

  // Hydrate initial value
  useEffect(() => {
    setItems(parseTree(initialValue));
  }, []);

  const itemLevels = useMemo(() => items.ordered.reduce((result, [uid, level]) => ({
    ...result,
    [uid]: level,
  }), {}), [JSON.stringify(items.ordered)]);

  // TODO
  const parseTree = (items, result = { byId: {}, ordered: [] }, level = 0) => {
    items.forEach(item => {
      const target = item.target && releaseItems.find(ri => ri.objectUid === item.target);
      const { children, ...rest } = item;
      result.byId[item.uid] = { ...rest, target };
      result.ordered.push([item.uid, level]);

      parseTree((item.children || []), result, level + 1);
    });

    return result;
  };

  const buildTree = srcIds => {
    return srcIds.map(uid => {
      const level = itemLevels[uid];
      const { target, ...rest } = items.byId[uid];
      const firstLevelChildIds = getItemChildren(uid).filter(u => itemLevels[u] === level + 1);
      return {
        ...rest,
        uid,
        target: target && target.objectUid,
        children: buildTree(firstLevelChildIds),
      };
    });
  };

  useEffect(() => {
    const topLevelItemIds = items.ordered.filter(([, level]) => level === 0).map(([uid]) => uid);
    setItemsStructured(buildTree(topLevelItemIds));
  }, [JSON.stringify(items)]);

  useEffect(() => {
    onChange(itemsStructured);
  }, [JSON.stringify(itemsStructured)]);

  // Supress Django form errors for items that have been deleted
  errorsFromProps = Object.entries(errorsFromProps).reduce((result, [key, value]) => {
    if (items.byId.hasOwnProperty(key)) {
      result[key] = value;
    }
    return result;
  }, {});

  const errors = !isEnabled ? {} : items.ordered.reduce((result, [uid, level]) => {
    if (level > maxNestingLevel) {
      const msg = `Item exceeds the maximum supported nesting depth (${maxNestingLevel + 1}).`;
      if (result.hasOwnProperty(uid)) {
        result[uid].nonFieldErrors = result[uid].nonFieldErrors || [];
        result[uid].nonFieldErrors.push(msg);
      } else {
        result[uid] = { nonFieldErrors: [msg] };
      }
    }

    const item = items.byId[uid];
    if (!item.external && !item.target) {
      const msg = 'The target object was removed from this release.';
      if (result.hasOwnProperty(uid)) {
        result[uid].target = result[uid].target || [];
        result[uid].target.push(msg);
      } else {
        result[uid] = { target: [msg] };
      }
    }

    return result;
  }, { ...errorsFromProps });

  const hasError = Object.keys(errors).length > 0;

  const handleStyleChange = evt => onStyleChange(evt.target.value);

  // Add position
  const [addPosition, setAddPosition] = useState(ls.get('releaseNav-addPosition') || ADD_POS_END);
  const handleAddPositionChange = evt => {
    const val = evt.target.value;
    setAddPosition(val);
    ls.set('releaseNav-addPosition', val);
  };

  const addItem = (uid, attrs) => setItems(prevState => ({
    byId: {
      ...prevState.byId,
      [uid]: {
        ...attrs,
      },
    },
    ordered: addPosition === ADD_POS_BEGINNING ? [
      [uid, 0],
      ...prevState.ordered,
    ] : [
      ...prevState.ordered,
      [uid, 0],
    ],
  }));

  const updateItem = (uid, attrs) => setItems(prevState => ({
    ...prevState,
    byId: {
      ...prevState.byId,
      [uid]: {
        ...prevState.byId[uid],
        ...attrs,
      },
    },
  }));

  // filter release items
  const [itemFilter, setItemFilter] = useState(ls.get('releaseNav-itemFilter') || FILTER_ALL);
  const handleItemFilterChange = evt => {
    evt.preventDefault();
    const val = decodeURIComponent(evt.target.hash.replace('#', ''));
    setItemFilter(val);
    ls.set('releaseNav-itemFilter', val);
  };

  const [itemFilterShowAll, setItemFilterShowAll] = useState(false);
  useEffect(() => {
    setItemFilterShowAll(false);
  }, [itemFilter]);

  const releaseContentListItems = useMemo(() => {
    return {
      [FILTER_ALL]: releaseItems,
      [FILTER_ADDED]: releaseItems.filter(({ uid }) => includedReleaseItems.includes(uid)),
      [FILTER_NOT_ADDED]: releaseItems.filter(({ uid }) => !includedReleaseItems.includes(uid)),
    };
  }, [releaseItems, JSON.stringify(includedReleaseItems)]);

  let showAllLink;
  let visibleReleaseItems = releaseContentListItems[itemFilter];
  if (!itemFilterShowAll && visibleReleaseItems.length > 10) {
    const extraCount = visibleReleaseItems.length - 10;
    showAllLink = (
      <a href="#show-all" onClick={pd(() => setItemFilterShowAll(true))}>
        Show {extraCount} more {pluralize(extraCount, 'item', null, true)}…
      </a>
    );
    visibleReleaseItems = visibleReleaseItems.slice(0, 10);
  }

  const getItemParent = uid => {
    const itemIndex = items.ordered.findIndex(item => item[0] === uid);
    const itemLevel = items.ordered[itemIndex][1];

    let prevIndex = itemIndex - 1;
    while (prevIndex >= 0) {
      const [uid, level] = items.ordered[prevIndex];
      if (level < itemLevel) {
        return uid;
      }
      prevIndex -= 1;
    }
    return null;
  };

  const getItemAncestors = uid => {
    const ancestorIds = [];
    let curItemParent = getItemParent(uid);
    while (curItemParent) {
      ancestorIds.push(curItemParent);
      curItemParent = getItemParent(curItemParent);
    }
    return ancestorIds;
  };

  const getItemChildren = uid => {
    const itemIndex = items.ordered.findIndex(item => item[0] === uid);
    const itemLevel = items.ordered[itemIndex][1];

    let nextIndex = itemIndex + 1;
    const childIds = [];

    const isChild = idx => {
      const targetLevel = items.ordered[idx] && items.ordered[idx][1];
      return targetLevel && targetLevel > itemLevel;
    };

    while (isChild(nextIndex)) {
      childIds.push(items.ordered[nextIndex][0]);
      nextIndex += 1;
    }

    return childIds;
  };

  const itemIsHidden = uid => {
    const ancestorIds = getItemAncestors(uid);
    return ancestorIds.some(id => itemsExpanded[id] === false);
  };

  const handleItemAddClick = targetUid => {
    const uid = uuid4();
    const target = releaseItems.find(item => item.uid === targetUid);
    const attrs = {
      label: target.objectName,
      slug: formatters.slug(target.objectName),
      url: null,
      external: false,
      target,
    };
    addItem(uid, attrs);
    const scrollY = addPosition === ADD_POS_END ? itemListRef.current.scrollHeight : 0;
    setTimeout(() => itemListRef.current.scrollTo(0, scrollY), 100);
  };

  const handleCustomLinkFormSubmit = ({ label, url }) => {
    const uid = uuid4();
    const attrs = {
      label,
      slug: formatters.slug(label),
      url,
      external: true,
      target: null,
    };
    addItem(uid, attrs);
  };

  // drag and drop sorting
  const handleItemMove = (sourceId, targetId, insertPos) => {
    const sourceIndex = items.ordered.findIndex(([uid]) => uid === sourceId);
    const targetIndex = items.ordered.findIndex(([uid]) => uid === targetId);

    if (insertPos === 'after' && sourceIndex === targetIndex + 1) return null;
    if (insertPos === 'before' && sourceIndex === targetIndex - 1) return null;

    const [, targetLevel] = items.ordered[targetIndex];

    // When inserting after a target item that already has child nodes,
    // the source becomes a child as well
    const targetChildren = getItemChildren(targetId);
    const targetHasChildren = targetChildren.length > 0;
    const targetIsCollapsed = itemsExpanded[targetId] === false;
    let level = insertPos === 'after' && targetHasChildren && !targetIsCollapsed ? targetLevel + 1 : targetLevel;
    if (targetIndex === 0) level = 0;

    const sourceChildren = dragSourceChildIds;
    const sourcePrevLevel = items.ordered[sourceIndex][1];
    const levelDelta = level - sourcePrevLevel;

    const newValue = items.ordered.filter(([uid]) => ![sourceId, ...sourceChildren].includes(uid));
    const movedItems = items.ordered.filter(([uid]) => [sourceId, ...sourceChildren].includes(uid)).map(([itemUid, itemLevel]) => [itemUid, itemLevel + levelDelta]);

    // prevent moving into a collapsed stack
    let _targetId = targetId;
    if (insertPos === 'after' && targetHasChildren && targetIsCollapsed) {
      _targetId = targetChildren.slice(-1)[0];
    }

    const targetNewIndex = newValue.findIndex(([uid]) => uid === _targetId);

    const toIndex = insertPos === 'before' ? targetNewIndex : targetNewIndex + 1;

    newValue.splice(toIndex, 0, ...movedItems);
    setItems(prevState => ({ ...prevState, ordered: newValue }));
  };

  const [dragSourceChildIds, setDragSourceChildIds] = useState([]);
  const [dragOutlineHeight, setDragOutlineHeight] = useState(null);
  const handleItemDragStart = sourceId => {
    const dragChildren = getItemChildren(sourceId);
    const visibleChildren = dragChildren.filter(id => !itemIsHidden(id));
    const isCollapsed = itemsExpanded[sourceId] === false;
    // Calculate height of drag preview box encapsulating all child nodes
    if (!isCollapsed && visibleChildren.length > 0) {
      const dragSourceEl = findDOMNode(itemRefs.current[sourceId]); // eslint-disable-line
      const lastChildEl = findDOMNode(itemRefs.current[visibleChildren.slice(-1)[0]]); // eslint-disable-line
      const height = lastChildEl.getBoundingClientRect().bottom - dragSourceEl.getBoundingClientRect().top;
      setDragOutlineHeight(height);
    }
    setDragSourceChildIds(dragChildren);
  };

  const handleItemDragEnd = sourceId => {
    setDragSourceChildIds([]);
    setDragOutlineHeight(null);
  };

  const [nestedItemId, setNestedItemId] = useState(null);
  const handleLevelChange = (uid, level) => {
    const prevLevel = items.ordered.find(([itemId]) => itemId === uid)[1];
    const levelDelta = level - prevLevel;
    const childIds = getItemChildren(uid);

    const newValue = items.ordered.map(([itemId, itemLevel]) => {
      if ([uid, ...childIds].includes(itemId)) {
        return [itemId, itemLevel + levelDelta];
      } else {
        return [itemId, itemLevel];
      }
    });

    setItems(prevState => ({ ...prevState, ordered: newValue }));
    setTimeout(() => setNestedItemId(uid), 200);
  };

  useEffect(() => {
    if (nestedItemId) {
      const parentId = getItemParent(nestedItemId);
      toggleItemExpandedState(parentId, true);
    }
    setNestedItemId(null);
  }, [nestedItemId]);

  const handleItemDelete = uid => {
    const toDelete = [uid, ...getItemChildren(uid)];
    setItems(prevState => ({
      byId: Object.entries(prevState.byId).reduce((result, [key, value]) => {
        if (!toDelete.includes(key)) result[key] = value;
        return result;
      }, {}),
      ordered: prevState.ordered.filter(([u]) => !toDelete.includes(u)),
    }));
  };

  // enable / disable editing state
  const [itemsEditing, setItemsEditing] = useState({});
  const itemIds = Object.keys(items.byId);
  useEffect(() => {
    setItemsEditing(itemIds.reduce((result, uid) => {
      let editing = uid in itemsEditing ? itemsEditing[uid] : false;
      if (errors[uid]) editing = true;
      result[uid] = editing;
      return result;
    }, {}));
  }, [JSON.stringify(itemIds)]);

  const toggleItemEditingState = uid => {
    setItemsEditing({
      ...itemsEditing,
      [uid]: !itemsEditing[uid],
    });
  };

  const toggleAllItemsEditingState = isEditing => {
    setItemsEditing(itemIds.reduce((result, uid) => ({ ...result, [uid]: isEditing }), {}));
  };

  const handleEditAllClick = e => {
    e.preventDefault();
    toggleAllItemsEditingState(true);
  };

  const handleEditNoneClick = e => {
    e.preventDefault();
    toggleAllItemsEditingState(false);
  };

  // expand / collapse hierarchy
  const [itemsExpanded, setItemsExpanded] = useState({});

  useEffect(() => {
    setItemsExpanded(items.ordered.reduce((result, [uid, _level]) => {
      result[uid] = uid in itemsExpanded ? itemsExpanded[uid] : true;
      return result;
    }, {}));
  }, [items.ordered]);

  const toggleItemExpandedState = (uid, state) => setItemsExpanded(oldState => {
    if (typeof state === 'undefined') state = !oldState[uid];
    return { ...oldState, [uid]: state };
  });

  const toggleAllItemsExpandedState = isExpanded => {
    setItemsExpanded(items.ordered.reduce((result, [uid, _level]) => {
      result[uid] = isExpanded;
      return result;
    }, {}));
  };

  const handleExpandAllClick = e => {
    e.preventDefault();
    toggleAllItemsExpandedState(true);
  };

  const handleExpandNoneClick = e => {
    e.preventDefault();
    toggleAllItemsExpandedState(false);
  };

  const renderedItems = items.ordered.map(([uid, level], idx) => {
    const item = items.byId[uid];

    const hasChildren = getItemChildren(uid).length > 0;
    const prevLevel = items.ordered[idx - 1] && items.ordered[idx - 1][1];

    const nextNonChildNode = items.ordered.find((curItem, curIdx) => curIdx > idx && curItem && curItem[1] <= level);
    const nextLevel = nextNonChildNode && nextNonChildNode[1];

    const canIndent = typeof prevLevel !== 'undefined' && level - prevLevel < 1 && level < maxNestingLevel;
    const canOutdent = (!nextLevel || nextLevel < level) && level > 0;
    const canSort = !dragSourceChildIds.includes(uid);

    const isHidden = itemIsHidden(uid);

    return (
      <NestedSortableItem
        key={uid}
        ref={el => itemRefs.current[uid] = el}
        itemId={uid}
        type="nav-builder-items"
        level={level}
        maxLevel={maxNestingLevel}
        canSort={canSort}
        canIndent={canIndent}
        canOutdent={canOutdent}
        className="mb-4"
        style={{
          opacity: canSort ? 1 : 0,
          display: isHidden ? 'none' : 'block',
          '--drag-outline-height': dragOutlineHeight && `${dragOutlineHeight}px`,
        }}
        onDragStart={handleItemDragStart}
        onDragEnd={handleItemDragEnd}
        onItemMove={handleItemMove}
        onLevelChange={handleLevelChange}
      >
        {(connectDragSource) => (
          <Item
            {...item}
            hasChildren={hasChildren}
            isExpanded={itemsExpanded[uid]}
            isEditing={itemsEditing[uid]}
            errors={errors[uid] || {}}
            connectDragSource={connectDragSource}
            onChange={attrs => updateItem(uid, attrs)}
            onDisclosureClick={() => toggleItemExpandedState(uid)}
            onEditClick={() => toggleItemEditingState(uid)}
            onDeleteClick={() => handleItemDelete(uid)}
          />
        )}
      </NestedSortableItem>
    );
  });

  const containerStyles = isEnabled ? {} : { pointerEvents: 'none', userSelect: 'none' };

  return (
    <div className="object-detail-container h-100">
      <div className="nav-builder object-detail-content h-100">
        <div className="object-detail-main">
          <div className="card-item d-flex align-items-center p-3 mb-4">
            <div className="text-hint">
              By default, navigation menus are generated automatically based on the contents of the release. Enable custom navigation to configure manually instead.
            </div>

            <label className="form-switch" style={{ marginLeft: 30, padding: '.1rem 2rem .1rem 0' }}>
              <input
                type="checkbox"
                checked={isEnabled}
                onChange={e => onEnabledChange(e.target.checked)}
              />
              <i className="form-icon" style={{ left: 'unset', right: 0 }} />
              <strong style={{ whiteSpace: 'nowrap' }}>Enable custom navigation</strong>
            </label>
          </div>

          <div className="nav-builder-items-wrapper" style={containerStyles}>
            <div className="form-group m-0">
              <h5 className="m-0">Navigation Items</h5>
              <div className="text-hint mb-2">Configure release navigation links.</div>
            </div>
            <ErrorBoundary>
              <div className="sc-builder">
                <div className="controls">
                  <div className="text-meta d-flex">
                    <div className="mr-4">
                      <strong>Show Child Items:</strong>&nbsp;&nbsp;<a href="#expand-all" onClick={handleExpandAllClick}>all</a>&nbsp; | &nbsp;<a href="#expand-none" onClick={handleExpandNoneClick}>none</a>
                    </div>
                    <div>
                      <strong>Toggle Editing:</strong>&nbsp;&nbsp;<a href="#edit-all" onClick={handleEditAllClick}>all</a>&nbsp; | &nbsp;<a href="#edit-none" onClick={handleEditNoneClick}>none</a>
                    </div>
                  </div>
                </div>
                {hasError && <div className="error-list">Some of the items below contain errors.</div>}
                <div className="item-list" ref={itemListRef}>
                  {renderedItems.length ? renderedItems : <div className="text-center my-4">No content yet! Add items from the right column to get started.</div>}
                </div>
              </div>
            </ErrorBoundary>
            {!isEnabled && <div className="title-builder-disabled-overlay" />}
          </div>
        </div>

        <div className="object-detail-sidebar">
          <div
            className="title-builder-form-container"
            style={{ position: 'relative', ...containerStyles }}
          >
            <header className="title-builder-form-header mb-2">
              <div>
                <h5><span>Options</span></h5>
              </div>
            </header>

            <FieldWrapper label="Navigation style">
              <label className="form-radio">
                <input
                  type="radio"
                  value={NAV_STYLE_NESTED}
                  checked={styleValue === 'nested'}
                  onChange={handleStyleChange}
                />
                <i className="form-icon" />
                <span>Compact</span>
                <div className="text-hint">Navigation is displayed as a single collapsible menu.</div>
              </label>

              <label className="form-radio">
                <input
                  type="radio"
                  value={NAV_STYLE_HYBRID}
                  checked={styleValue === 'hybrid'}
                  onChange={handleStyleChange}
                />
                <i className="form-icon" />
                <span>Expanded</span>
                <div className="text-hint">Top-level navigation items are exposed.</div>
              </label>
            </FieldWrapper>

            <header className="title-builder-form-header mb-2">
              <div>
                <h5><span>Add Navigation Items</span></h5>
              </div>
            </header>

            <div className="form-group m-0">
              <div className="flex-spread">
                <strong>Add new items to:</strong>
                <div>
                  <label className="form-radio form-inline mr-3">
                    <input
                      type="radio"
                      value={ADD_POS_BEGINNING}
                      checked={addPosition === ADD_POS_BEGINNING}
                      onChange={handleAddPositionChange}
                    />
                    <i className="form-icon" /> Beginning
                  </label>
                  <label className="form-radio form-inline">
                    <input
                      type="radio"
                      value={ADD_POS_END}
                      checked={addPosition === ADD_POS_END}
                      onChange={handleAddPositionChange}
                    />
                    <i className="form-icon" /> End
                  </label>
                </div>
              </div>
              <hr />
            </div>

            <div className="form-group mb-4">
              <label className="block-label strong">Release Content</label>
              <div className="form-input-hint mt-0 mb-1">Link to items within this release. If you have unsaved changes, you may need to save to refresh this list.</div>
            </div>

            <div className="mb-2">
              <span>Filter items: &nbsp;</span>
              {FILTER_CHOICES.map((c, idx, arr) => (
                <Fragment key={c}>
                  {itemFilter === c
                    ? <strong>{c}</strong>
                    : <a href={`#${c}`} onClick={handleItemFilterChange}>{c}</a>}
                  {idx < (arr.length - 1) && <span className="text-meta"> &middot; </span>}
                </Fragment>
              ))}
            </div>

            <div className="mb-6">
              {visibleReleaseItems.map(({ uid, objectName, objectIconName, objectType }, idx) => (
                <Fragment key={uid}>
                  <hr />
                  <div className="d-flex align-items-center">
                    <Icon name={objectIconName} size={20} className="text-meta mr-2" />
                    <div style={{ flex: 1 }}>{objectName} ({objectType})</div>
                    <Icon
                      name="check_circle"
                      size={20}
                      className="text-success mx-2"
                      style={{ fontSize: 20, transition: 'opacity .15s linear', opacity: includedReleaseItems.includes(uid) ? 1 : 0 }}
                    />
                    <button type="button" className="btn btn-sm" onClick={() => handleItemAddClick(uid)}>Add</button>
                  </div>
                </Fragment>
              ))}
              <hr />
              {showAllLink}
            </div>

            <CustomLinkForm onSubmit={handleCustomLinkFormSubmit} />

            {!isEnabled && <div className="title-builder-disabled-overlay" />}
          </div>
        </div>
      </div>
    </div>
  );
};

const itemShape = {
  label: PropTypes.string,
  slug: PropTypes.string,
  url: PropTypes.string,
  external: PropTypes.bool,
  target: PropTypes.string,
};
itemShape.children = PropTypes.arrayOf(PropTypes.shape(itemShape));

NavBuilder.propTypes = {
  isEnabled: PropTypes.bool,
  styleValue: PropTypes.oneOf([NAV_STYLE_NESTED, NAV_STYLE_HYBRID]),
  initialValue: PropTypes.arrayOf(PropTypes.shape(itemShape)),
  errors: PropTypes.object,
  maxNestingLevel: PropTypes.number,
  releaseItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    uid: PropTypes.string,
    objectId: PropTypes.string,
    objectUid: PropTypes.string,
    objectType: PropTypes.string,
    objectName: PropTypes.string,
    objectIconName: PropTypes.string,
  })),
  onChange: PropTypes.func,
  onEnabledChange: PropTypes.func,
  onStyleChange: PropTypes.func,
};

export default withDragDropContext(NavBuilder);
